var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticStyle: { "margin-bottom": "8px" },
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "a-col",
            [
              _vm.dataSource.length
                ? _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: _vm.$t("Are you sure to delete selected?"),
                        disabled: !_vm.indeterminate && !_vm.selectAll
                      },
                      on: { confirm: _vm.handleDeleteSelected }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            ghost: "",
                            icon: "delete",
                            disabled: !_vm.indeterminate && !_vm.selectAll
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Delete selected word")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            [
              _c(
                "span",
                {
                  staticStyle: { "margin-right": "8px", "font-weight": "bold" }
                },
                [_vm._v(_vm._s(_vm.$t("Gender")) + ":")]
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "120px" },
                  model: {
                    value: _vm.gender,
                    callback: function($$v) {
                      _vm.gender = $$v
                    },
                    expression: "gender"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: "female" } }, [
                    _vm._v(_vm._s(_vm.$t("Female")))
                  ]),
                  _c("a-select-option", { attrs: { value: "male" } }, [
                    _vm._v(_vm._s(_vm.$t("Male")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "DraggableTable",
        {
          attrs: { columns: _vm.columns, "data-list": _vm.sortedDataSource },
          on: {
            change: function(value) {
              return (_vm.dataSource = value)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "order",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "a-tooltip",
                        {
                          attrs: {
                            placement: "top",
                            title: _vm.$t("Drag to reorder")
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: {
                                padding: "0",
                                margin: "0",
                                "line-height": "normal"
                              },
                              attrs: { type: "link" }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticStyle: { width: "20px" },
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("a-checkbox", {
                        staticStyle: { "margin-left": "4px" },
                        attrs: { checked: record.isSelecting },
                        on: {
                          change: function(ref) {
                            var target = ref.target

                            return _vm.handleSelectItem(target.checked, index)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "index",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [_vm._v(" " + _vm._s(index + 1) + " ")]
              }
            },
            {
              key: "word",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [
                  _c("a-input", {
                    ref: "wordInputRefs" + index,
                    staticStyle: { margin: "-5px 0" },
                    attrs: { value: text },
                    on: {
                      change: function(e) {
                        return _vm.handleChangeInputField(
                          e.target.value,
                          "word",
                          index
                        )
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        ) {
                          return null
                        }
                        return (function() {
                          return (
                            index + 1 == _vm.dataSource.length &&
                            !text &&
                            !record.reading &&
                            !record.note &&
                            _vm.handlePressDelete(index)
                          )
                        })($event)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "reading",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [
                  _c("a-input", {
                    staticStyle: { margin: "-5px 0" },
                    attrs: { value: text },
                    on: {
                      change: function(e) {
                        return _vm.handleChangeInputField(
                          e.target.value,
                          "reading",
                          index
                        )
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "note",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("a-input", {
                        ref: "noteInputRefs" + index,
                        staticStyle: { margin: "-5px 0" },
                        attrs: { value: text },
                        on: {
                          change: function(e) {
                            return _vm.handleChangeInputField(
                              e.target.value,
                              "note",
                              index
                            )
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return (function() {
                              return (
                                index + 1 == _vm.dataSource.length &&
                                _vm.handlePressEnter(index + 1)
                              )
                            })($event)
                          }
                        }
                      }),
                      _c("a-button", {
                        staticStyle: {
                          position: "absolute",
                          bottom: "-25px",
                          right: "-21px"
                        },
                        attrs: {
                          icon: "plus",
                          type: "primary",
                          shape: "circle",
                          size: "small"
                        },
                        on: {
                          click: function() {
                            return _vm.handleAddAtIndex(index + 1)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "operation",
              fn: function(ref) {
                var text = ref.text
                var record = ref.record
                var index = ref.index
                return [
                  _vm.dataSource.length
                    ? _c(
                        "a-popconfirm",
                        {
                          attrs: { title: _vm.$t("Are you sure to delete?") },
                          on: {
                            confirm: function() {
                              return _vm.handleDelete(index)
                            }
                          }
                        },
                        [
                          _c("a-button", {
                            staticStyle: { margin: "2px" },
                            attrs: {
                              icon: "delete",
                              type: "danger",
                              ghost: "",
                              size: "small"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("a-button", {
                    staticStyle: { margin: "2px" },
                    attrs: {
                      icon: record.isPlaying ? "pause-circle" : "play-circle",
                      type: "primary",
                      ghost: "",
                      size: "small",
                      disabled: !record.reading
                    },
                    on: {
                      click: function() {
                        return record.isPlaying
                          ? _vm.handlePause(record)
                          : _vm.handlePlay(record)
                      }
                    }
                  })
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "customOrder" },
            [
              _c("a-checkbox", {
                attrs: {
                  indeterminate: _vm.indeterminate,
                  checked: _vm.selectAll
                },
                on: { change: _vm.handleSelectAll }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "customOperation" },
            [
              _c(
                "a-tooltip",
                { attrs: { placement: "top", title: _vm.$t("Add") } },
                [
                  _c("a-button", {
                    attrs: {
                      shape: "circle",
                      type: "primary",
                      icon: "plus-circle"
                    },
                    on: { click: _vm.handleAddLast }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "8px" } },
        [
          _c(
            "a-popconfirm",
            {
              attrs: {
                "ok-text": _vm.$t("Yes"),
                "cancel-text": _vm.$t("No"),
                title: _vm.$t("Are you sure to update this dictionary?")
              },
              on: { confirm: _vm.handleUpdateDictionary }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: {
                    type: "primary",
                    loading: _vm.loading,
                    icon: "save"
                  },
                  on: {
                    click: function(e) {
                      return e.stopPropagation()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }