var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "add-dictionary-dialog",
      attrs: {
        visible: _vm.visible,
        closable: false,
        "mask-closable": false,
        destroyOnClose: "",
        title: _vm.$t("New Dictionary")
      }
    },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("Dictionary name") } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["dictionaryName", _vm.formItemOption],
                    expression: "['dictionaryName', formItemOption]"
                  }
                ],
                attrs: { placeholder: "e.g., dictionaryA, dictionary123" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { staticClass: "mr-xs", on: { click: _vm.handleClose } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSubmitForm($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }