//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import DraggableTable from '@/components/common/DraggableTable.vue';

export default {
  components: { DraggableTable },
  props: {
    dictionary: { type: Object, default: () => ({}) }
  },
  data: () => ({
    loading: false,
    dataSource: [],
    audio: null,
    gender: 'female'
  }),
  computed: {
    ...mapGetters('remoteConfig', ['pollyEngine']),
    columns() {
      return [
        {
          dataIndex: 'order',
          width: '20px',
          align: 'center',
          slots: { title: 'customOrder' },
          scopedSlots: { customRender: 'order' }
        },
        {
          title: 'No',
          dataIndex: 'index',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: this.$t('Word'),
          dataIndex: 'word',
          width: '25%',
          scopedSlots: { customRender: 'word' }
        },
        {
          title: this.$t('Reading'),
          dataIndex: 'reading',
          width: '25%',
          scopedSlots: { customRender: 'reading' }
        },
        {
          title: this.$t('Note'),
          dataIndex: 'note',
          width: '25%',
          scopedSlots: { customRender: 'note' }
        },
        {
          dataIndex: 'operation',
          align: 'center',
          slots: { title: 'customOperation' },
          scopedSlots: { customRender: 'operation' }
        }
      ];
    },
    content() {
      return this.dictionary?.content || [];
    },
    lang() {
      return this.dictionary?.language || 'en-US';
    },
    selectAll() {
      if (this.dataSource.length === 0) return false;
      return this.dataSource.every(item => item.isSelecting);
    },
    indeterminate() {
      return !this.selectAll && this.dataSource.some(item => item.isSelecting);
    },
    sortedDataSource() {
      const newDataSource = this.dataSource;
      return newDataSource.sort((a, b) => a.order - b.order);
    }
  },
  methods: {
    ...mapActions('dictionary', ['updateDictionary']),
    handleAddLast() {
      const newData = {
        word: '',
        reading: '',
        note: '',
        isPlaying: false,
        isSelecting: false,
        order: this.sortedDataSource?.length + 1
      };
      this.dataSource = [...this.sortedDataSource, newData];
    },
    handleAddAtIndex(index) {
      const newDataSource = [...this.sortedDataSource];
      const newData = {
        word: '',
        reading: '',
        note: '',
        isPlaying: false,
        isSelecting: false
      };
      newDataSource.splice(index, 0, newData);
      this.dataSource = newDataSource.map((item, index) => ({ ...item, order: index + 1 }));
    },
    handleDelete(index) {
      const newDataSource = [...this.sortedDataSource];
      newDataSource.splice(index, 1);
      this.dataSource = newDataSource.map((item, index) => ({
        ...item,
        order: index + 1
      }));
    },
    handleChangeInputField(value, type, index) {
      const newDataSource = [...this.sortedDataSource];
      const target = newDataSource[index];
      if (target) {
        target[type] = value;
        this.dataSource = newDataSource;
      }
    },
    handleSubmit() {
      return this.sortedDataSource
        .filter(item => item.word && item.reading)
        .map((item, index) => ({
          word: item.word,
          reading: item.reading,
          order: index + 1,
          note: item.note || ''
        }));
    },
    async getSynthesizeSpeech(text, lang) {
      const payload = {
        sentence: text,
        gender: this.gender || 'female',
        language: lang,
        textType: 'text',
        engine: this.pollyEngine || 'standard'
      };
      const res = await this.$s.api.sound.getSynthesizeSpeechStream(payload);
      if (!res?.mp3Stream?.data) return '';

      const bufferArray = res?.mp3Stream?.data;
      const audioBlob = new Blob([new Uint8Array(bufferArray)], {
        type: 'audio/mp3'
      });
      const url = URL.createObjectURL(audioBlob);
      console.log(url);

      return url;
    },
    async handlePlay(record) {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
      try {
        record.isPlaying = true;
        const { reading } = record;
        const url = await this.getSynthesizeSpeech(reading, this.lang);
        this.audio = new Audio(url);
        this.audio.play();
        this.audio.onended = () => this.handleEnd(record);
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.TypeError) || this.$t('Network error')
        });
      }
    },
    handleEnd(record) {
      record.isPlaying = false;
    },
    handlePause(record) {
      record.isPlaying = false;
      this.audio.pause();
      this.audio = null;
    },
    async handleUpdateDictionary() {
      try {
        this.loading = true;
        const payload = {
          ...this.dictionary,
          content: this.handleSubmit()
        };
        const res = await this.$s.api.dictionary.updateDictionary(payload.sk, payload);
        this.updateDictionary(res);
        this.$notification.success({
          message: this.$t('Update dictionary successfully')
        });
      } catch (error) {
        this.$notification.error({ message: error.message });
      } finally {
        this.loading = false;
      }
    },
    handleSelectAll({ target }) {
      this.dataSource = this.sortedDataSource.map(item => ({
        ...item,
        isSelecting: target.checked
      }));
    },
    handleSelectItem(checked, index) {
      const newDataSource = [...this.sortedDataSource];
      newDataSource[index].isSelecting = checked;
      this.dataSource = [...newDataSource];
    },
    handleDeleteSelected() {
      this.dataSource = this.sortedDataSource
        .filter(item => !item.isSelecting)
        .map((item, index) => ({ ...item, order: index + 1 }));
      // this.handleUpdateDictionary();
    },
    handlePressEnter(index) {
      this.handleAddLast();
      this.$nextTick(() => {
        this.$refs[`wordInputRefs${index}`]?.focus();
      });
    },
    handlePressDelete(index) {
      this.handleDelete(index);
      this.$nextTick(() => {
        this.$refs[`noteInputRefs${index - 1}`]?.focus();
      });
    }
  },
  watch: {
    content: {
      handler() {
        this.dataSource = [];
        if (!this.content) return;
        this.content.forEach((item, index) => {
          this.dataSource.push({
            order: index + 1,
            note: '',
            ...item,
            isPlaying: false,
            isSelecting: false
          });
        });
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
  }
};
