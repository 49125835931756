//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    visible: { type: Boolean, default: false },
    lang: { type: String, default: '' }
  },
  data() {
    return {
      loading: false,
      deleteConfirmInput: ''
    }
  },
  computed: {
    ...mapGetters('dictionary', ['dictionaryNamesByLang']),
  },
  methods: {
    ...mapActions('dictionary', ['deleteDictionaryByNames']),
    handleClose() {
      this.deleteConfirmInput = ''
      this.$emit('close')
    },
    async handleSubmit() {
      try {
        this.loading = true
        const dictionaryNames = this.dictionaryNamesByLang(this.lang)
        if (dictionaryNames?.length > 0) {
          await this.$s.api.dictionary.deleteDictionaries(dictionaryNames);
        }
        this.deleteDictionaryByNames(dictionaryNames)
        this.$notification.success({
          message: this.$t("Delete dictionary successfully")
        });
        this.handleClose()
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
      this.loading = false
    }
  }
}
