var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-divider",
        { staticStyle: { "margin-top": "0" }, attrs: { orientation: "left" } },
        [_vm._v(_vm._s(_vm.$t("Dictionary setting")))]
      ),
      _c(
        "a-card",
        [
          _c(
            "a-tabs",
            {
              attrs: { type: "editable-card" },
              on: { edit: _vm.handleEditTabs },
              model: {
                value: _vm.currentTab,
                callback: function($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab"
              }
            },
            _vm._l(_vm.dictionariesGroupByLang, function(dictionaryByLang) {
              return _c(
                "a-tab-pane",
                {
                  key: dictionaryByLang.lang,
                  attrs: { tab: _vm.$t(dictionaryByLang.lang) }
                },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "mb-sm",
                      attrs: { type: "flex", justify: "end" }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: {
                            click: function($event) {
                              _vm.addDictionaryDialogVisible = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Add dictionary")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-collapse",
                    {
                      attrs: { "expand-icon-position": "right" },
                      model: {
                        value: _vm.activeKey,
                        callback: function($$v) {
                          _vm.activeKey = $$v
                        },
                        expression: "activeKey"
                      }
                    },
                    _vm._l(dictionaryByLang.children, function(dic, index) {
                      return _c(
                        "a-collapse-panel",
                        { key: dic.name },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "8px" } },
                                [_vm._v(_vm._s(dic.name))]
                              )
                            ]
                          ),
                          _c("dictionary-table", {
                            attrs: { dictionary: dic }
                          }),
                          _c(
                            "template",
                            { slot: "extra" },
                            [
                              _c(
                                "a-row",
                                {
                                  attrs: {
                                    gutter: 8,
                                    type: "flex",
                                    justify: "center",
                                    align: "middle"
                                  }
                                },
                                [
                                  _c(
                                    "a-col",
                                    [
                                      _c("a-button", {
                                        attrs: {
                                          type: "primary",
                                          ghost: "",
                                          size: "small",
                                          icon: "edit"
                                        },
                                        on: {
                                          click: function(e) {
                                            return _vm.handleOpenUpdateDictionaryNameDialog(
                                              e,
                                              dic
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            placement: "left",
                                            title: _vm.$t(
                                              "Are you sure to delete this dictionary?"
                                            ),
                                            "ok-text": _vm.$t("Yes"),
                                            "cancel-text": _vm.$t("No")
                                          },
                                          on: {
                                            confirm: function() {
                                              return _vm.handleDeleteDictionary(
                                                dic.name
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-button", {
                                            attrs: {
                                              type: "danger",
                                              ghost: "",
                                              size: "small",
                                              icon: "delete",
                                              loading: _vm.deleteLoading
                                            },
                                            on: {
                                              click: function(e) {
                                                return e.stopPropagation()
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    }),
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("add-dictionary-dialog", {
        attrs: {
          visible: _vm.addDictionaryDialogVisible,
          lang: _vm.currentTab
        },
        on: {
          close: function($event) {
            _vm.addDictionaryDialogVisible = false
          }
        }
      }),
      _c("add-dictionary-by-lang-dialog", {
        attrs: { visible: _vm.addDictionaryByLangDialogVisible },
        on: {
          close: function($event) {
            _vm.addDictionaryByLangDialogVisible = false
          }
        }
      }),
      _c("delete-dictionary-by-lang-dialog", {
        attrs: {
          visible: _vm.deleteDictionaryByLangDialogVisible,
          lang: _vm.removedLangTarget
        },
        on: {
          close: function($event) {
            _vm.deleteDictionaryByLangDialogVisible = false
          }
        }
      }),
      _c("update-dictionary-name-dialog", {
        ref: "updateDictionaryNameDialogRef"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }