var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "add-dictionary-by-lang-dialog",
      attrs: {
        visible: _vm.visible,
        closable: false,
        "mask-closable": false,
        title: _vm.$t("New dictionary language"),
        destroyOnClose: ""
      },
      on: { cancel: _vm.handleClose }
    },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            {
              staticStyle: { "margin-bottom": "2px" },
              attrs: { label: _vm.$t("Language") }
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["language", _vm.formItemOption.language],
                      expression: "['language', formItemOption.language]"
                    }
                  ],
                  style: { width: "100%" },
                  attrs: { placeholder: _vm.$t("Select dictionary language") }
                },
                _vm._l(_vm.langOptions, function(lang) {
                  return _c(
                    "a-select-option",
                    { key: lang.value, attrs: { value: lang.value } },
                    [_vm._v(" " + _vm._s(_vm.$t(lang.label)) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              staticStyle: { "margin-bottom": "2px" },
              attrs: { label: _vm.$t("Dictionary name") }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["name", _vm.formItemOption.name],
                    expression: "['name', formItemOption.name]"
                  }
                ],
                attrs: { placeholder: "e.g., dictionaryA, dictionary123" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "a-button",
            { staticClass: "mr-xs", on: { click: _vm.handleClose } },
            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                "html-type": "submit",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleSubmitForm($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Create")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }