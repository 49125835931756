//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { LANGUAGE_OPTIONS } from '@/enum'
export default {
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: "dictionary" }),
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('dictionary', ['dictionaryLangs']),
    langOptions() {
      const that = this
      return Object.entries(LANGUAGE_OPTIONS).map(([key, value]) => ({
        value: key,
        label: that.$t(value)
      })).filter(item => !this.dictionaryLangs.includes(item.value))
    },
    formItemOption() {
      return {
        name: {
          rules: [
            { required: true, message: this.$t('Please input dictionary name') },
            { max: 20, message: this.$t('Dictionary name must be less than 20 characters') },
          ]
        },
        language: {
          rules: [
            { required: true, message: this.$t('Please input language') },
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions('dictionary', ['addDictionary']),
    handleClose() {
      this.form.resetFields()
      this.$emit('close')
    },
    handleSubmitForm() {
      this.form.validateFields(async (err, values) => {
        if (err) return;
        this.handleAdd(values)
      })
    },
    async handleAdd(values) {
      try {
        this.loading = true
        const payload = {
          name: values.name,
          content: [],
          language: values.language,
          company_id: this.userInfo.company_id,
        }
        const res = await this.$s.api.dictionary.createDictionary(payload);
        this.addDictionary(res)
        this.$notification.success({
          message: this.$t("Create dictionary successfully")
        });
        this.handleClose()
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
      this.loading = false
    }
  }
}
