//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import DictionaryTable from './DictionaryTable.vue';
import AddDictionaryDialog from './AddDictionaryDialog.vue'
import AddDictionaryByLangDialog from './AddDictionaryByLangDialog.vue';
import DeleteDictionaryByLangDialog from './DeleteDictionaryByLangDialog.vue';
import UpdateDictionaryNameDialog from './UpdateDictionaryNameDialog';

export default {
  components: {
    DictionaryTable,
    AddDictionaryDialog,
    AddDictionaryByLangDialog,
    DeleteDictionaryByLangDialog,
    UpdateDictionaryNameDialog
  },
  data() {
    return {
      loading: false,
      deleteLoading: false,
      currentTab: null,
      addDictionaryDialogVisible: false,
      addDictionaryByLangDialogVisible: false,
      deleteDictionaryByLangDialogVisible: false,
      removedLangTarget: '',
      activeKey: ''
    }
  },
  computed: {
    ...mapGetters('dictionary', ['dictionaryLangs', 'dictionariesGroupByLang', 'dictionaryByName']),
    dictionaryName() {
      return this.$route?.query?.dicName
    },
  },
  methods: {
    ...mapActions('dictionary', ['updateDictionary', 'deleteDictionaryByNames']),
    async handleDeleteDictionary(name) {
      try {
        this.deleteLoading = true;
        await this.$s.api.dictionary.deleteDictionaries([name]);
        this.deleteDictionaryByNames([name])
        this.$notification.success({
          message: this.$t("Delete dictionary successfully")
        });
      } catch (error) {
        this.$notification.error({ message: error.message });
      } finally {
        this.deleteLoading = false;
      }
    },
    handleEditTabs(targetKey, action) {
      if (action === "add") {
        this.addDictionaryByLangDialogVisible = true;
      }
      if (action === "remove") {
        this.deleteDictionaryByLangDialogVisible = true;
        this.removedLangTarget = targetKey
      }
    },
    handleOpenUpdateDictionaryNameDialog(e, dic) {
      e.stopPropagation()
      this.$refs.updateDictionaryNameDialogRef.handleOpen(dic)
    }
  },
  watch: {
    dictionaryLangs: {
      handler() {
        if (this.dictionaryLangs.length > 0 && !this.currentTab) {
          const dictionary = this.dictionaryByName(this.dictionaryName)
          if (dictionary) {
            this.currentTab = dictionary.language
            this.activeKey = dictionary.name
          } else {
            this.currentTab = this.dictionaryLangs[0]
          }
        }
      },
      immediate: true
    },
  }
}
