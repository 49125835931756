//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    visible: { type: Boolean, default: false },
    lang: { type: String, default: 'eng' }
  },
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: "dictionary" }),
    }
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    formItemOption() {
      return {
        rules: [
          {
            required: true,
            message: this.$t('Please input dictionary name')
          },
          {
            max: 20,
            message: this.$t('Dictionary name must be less than 20 characters')
          },
        ]
      }
    }
  },
  methods: {
    ...mapActions('dictionary', ['addDictionary']),
    handleClose() {
      this.$emit('close')
    },
    handleSubmitForm() {
      this.form.validateFields(async (err, values) => {
        if (err) return;
        this.handleCreateDictionary(values.dictionaryName)
      })
    },
    async handleCreateDictionary(dictionaryName) {
      try {
        this.loading = true
        const payload = {
          name: dictionaryName,
          content: [],
          language: this.lang,
          company_id: this.userInfo.company_id,
        }
        const res = await this.$s.api.dictionary.createDictionary(payload);
        this.addDictionary(res)
        this.$notification.success({
          message: this.$t("Create dictionary successfully")
        });
        this.handleClose()
      } catch (error) {
        this.$notification.error({ message: error.message });
      }
      this.loading = false
    }
  }
}
