import { render, staticRenderFns } from "./DictionaryTable.vue?vue&type=template&id=58241c37&"
import script from "./DictionaryTable.vue?vue&type=script&lang=js&"
export * from "./DictionaryTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src277794545/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58241c37')) {
      api.createRecord('58241c37', component.options)
    } else {
      api.reload('58241c37', component.options)
    }
    module.hot.accept("./DictionaryTable.vue?vue&type=template&id=58241c37&", function () {
      api.rerender('58241c37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SpeechSetting/DictionaryTable.vue"
export default component.exports